
    @import "config.scss";
 
.selectButton {
  width: 50%;
  max-width: 160px;
  border: 1px var(--color-primary) solid;
  display: flex;
  justify-content: center;
  padding-inline: 20px;
  padding-block: 15px;
  margin-bottom: 20px;
  background: transparent;
  border-radius: 10px;
  align-items: center;
  cursor: pointer;

  svg {
    color: var(--color-primary);
  }
  .text {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;

    @include for-desktop-up {
      width: 95%;
    }
  }

  h3 {
    font-size: 16px;
    margin-right: 50px;
    text-align: initial;
    width: 100%;
  }

  span {
    font-size: 11px;
  }
}

.icon {
  // display: none;

  @include for-desktop-up {
    display: flex;
    margin-right: 5%;
  }
}

.free {
  @include for-desktop-up {
    margin-left: 2rem;
  }
}
