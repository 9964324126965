
    @import "config.scss";
 
.container {
  color: var(--color-white);
  width: 100%;
  display: flex;
  cursor: pointer;
  flex-direction: column;
  padding: 15px;
  background-color: var(--color-grey-dark);
  border: none;
  border-radius: 10px;
  margin-block: 25px;
  max-width: 350px;

  &.multiple {
    height: 140px;
    width: 100%;
    align-items: center;
    margin: 0px;
  }

  &:hover {
    border: solid 1px var(--color-primary);
  }
}

.cardData {
  display: flex;
  width: 100%;
  align-items: flex-end;
  justify-content: space-between;
  gap: 15px;

  &.multiple {
    flex-direction: column;
    margin-top: 10px;
    display: block;
  }
}

.issuer {
  min-height: 4em;
  background-repeat: no-repeat;
  background-size: 5.7em;
  width: 9em;
}

.brand {
  display: flex;
  flex-direction: column;
  align-items: center;

  &.multiple {
    margin-top: 20px;
  }
}
