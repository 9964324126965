
    @import "config.scss";
 
.backdrop {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 100000000000000000;
  top: 0;
  left: 0;
  overflow: hidden;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5019607843);
  align-items: center;
  overflow-y: scroll;
  display: flex;
}
.modal {
  z-index: var(--z-modal);
  height: auto;
  border-radius: 30px;
  padding-block: 3rem;
  margin: auto;
  position: relative;
  overflow-y: auto;

  &::-webkit-scrollbar {
    display: none;
  }
  .iconBtn {
    z-index: var(--z-modal-close-icon);
    cursor: pointer;
    position: absolute;
    width: 2rem;
    height: 2rem;
    top: 1rem;
    right: 1rem;
    appearance: none;
    border: none;
    background: transparent;
    padding: 0.5rem;
  }

  @include for-desktop-up {
    height: auto;
    padding: 0px;
  }
}

.fullModal {
  width: 100%;
  height: 100vh;
  inset: 0;
  position: sticky;
  background-color: var(--color-grey);
  border-radius: 0;
  padding: 3rem;
}

.open {
  animation: 0.3s linear 0s forwards openAnimation;
  display: block;
}

.close {
  animation: 0.3s linear 0s forwards closeAnimation;
}

@keyframes openAnimation {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes closeAnimation {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}
