
    @import "config.scss";
 
.spinner {
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--z-spinner);
  height: 100vh;
  width: 100vw;
  background-color: var(--color-background-80);

  .image {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    img {
      border-radius: 50%;
    }
  }

  .border {
    position: absolute;
    animation: 2s linear infinite spinner;
    animation-play-state: inherit;
    border: 4px solid transparent;
    border-radius: 50%;
    background: linear-gradient(var(--color-primary), transparent) border-box;
    -webkit-mask: linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    content: '';
    height: 145px;
    width: 145px;
    display: inline-block;
  }

  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}
