
    @import "config.scss";
 
.container {
  display: flex;
  position: relative;
  border-radius: 15px;
  overflow: hidden;
  margin-block: 15px;
  align-items: center;
  background-color: white;
}

.loading {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.8;
  display: flex;
  align-items: center;
  justify-content: center;
}

.product {
  height: 100%;

  .image {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 100%;
    width: 100px;

    img {
      width: 100px !important;
    }
  }
}

.info {
  padding: 10px 0.5rem 1rem;
  height: 100%;
  min-width: 50%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  .firstLines {
    .firstLine {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #fff;
      gap: 2rem;

      a {
        color: white;
      }

      a:hover {
        color: blue;
      }

      svg {
        color: var(--color-primary);
      }

      .btn {
        width: auto;
        height: auto;
        display: block;

        div {
          align-items: flex-start;
        }
      }

      .title {
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
      }
    }
    .firstOneLine {
      justify-content: space-around;
    }

    .secondLine {
      .textInSecondLine {
        font-size: 10px;
        display: inline;
        margin-right: 0.5rem;
      }
    }
  }

  .price {
    display: flex;
    gap: 1rem;
    align-items: center;
    width: 75px;

    @media (min-width: 670px) {
      justify-content: flex-end;
    }
  }
  .thirdLine {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    margin-top: 0.5rem;
    flex-wrap: wrap;
    gap: 0.6rem;

    .btn {
      width: 'auto';
      height: 'auto';
      display: none;
    }

    @media (min-width: 670px) {
      width: 100%;
      flex-wrap: nowrap;
    }
  }
}
