
    @import "config.scss";
 
.form {
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.showInvoiceRequirements {
  display: flex;
  gap: 1rem;
  flex-direction: column;
  li {
    display: grid;
    grid-template-columns: 16px 1fr;
    align-items: center;
    .coloredText {
      color: var(--color-primary);
    }
  }
}
